import { PureComponent } from 'react';
import {
  CardSuit,
  ICard,
  suitToNamed,
  valueToNamed,
} from '../../../Models/CardModels';
import Flame from '../Flame/Flame';
import { IUnrevealedCard } from '../../../Models/StateModels';

export interface ICardProps {
  card: ICard | IUnrevealedCard;
  hasFlame?: boolean;
  invisible?: boolean;
  none?: boolean;
}

class Card extends PureComponent<ICardProps> {
  public render() {
    const style = this.props.none
      ? ({ position: 'absolute', left: 0, top: 0 } as const)
      : {};

    const card = this.props.card;
    let cardPngName;
    if (
      !('value' in card) ||
      (card.suit !== CardSuit.HEARTS &&
        card.suit !== CardSuit.DIAMONDS &&
        card.suit !== CardSuit.CLUBS &&
        card.suit !== CardSuit.SPADES)
    ) {
      cardPngName = '/Cards/card_back.png';
    } else {
      cardPngName = `/Cards/${valueToNamed(card.value)}_of_${suitToNamed(card.suit)}.png`;
    }

    const className =
      'w-20 aspect-[0.714] md:w-40 max-w-[unset]  m-1 border border-black rounded-lg';

    const altText =
      'value' in card
        ? `${valueToNamed(card.value)} of ${suitToNamed(card.suit)}`
        : 'Unknown Card';

    return (
      <div
        key={this.props.card.cardId}
        style={style}
        className={`touch-none ${this.props.invisible || this.props.none ? 'opacity-0' : ''}`}
      >
        {this.props.hasFlame ? (
          <>
            {Flame('1.5vmin')}
            {Flame('7vmin')}
          </>
        ) : undefined}

        <img
          alt={altText}
          draggable={false}
          src={cardPngName}
          className={
            className +
            ' ' +
            (this.props.invisible || this.props.none ? 'card-invisible' : '')
          }
        />
      </div>
    );
  }
}

export default Card;
