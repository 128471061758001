import { useDroppable } from '@dnd-kit/core';
import classNames from 'classnames';
import { ICard } from '../../../Models/CardModels';
import { IPlay, IUnrevealedCard } from '../../../Models/StateModels';
import BurntCards from '../BurntCards/BurntCards';
import Deck from '../Deck/Deck';
import Pile from '../Pile/Pile';
import Card from '../Card/Card';

export const PLAY_AREA_ID = 'PLAY_ZONE';

interface IDeckAndPileProps {
  deckRemaining: IUnrevealedCard[];
  burntCards: IUnrevealedCard[];
  lastPlayedCard: ICard | IUnrevealedCard | undefined;
  playedCards: (ICard | IUnrevealedCard)[];
  availablePlays: IPlay[];
}

const DeckAndPile = ({
  deckRemaining,
  lastPlayedCard,
  playedCards,
  burntCards,
  availablePlays,
}: IDeckAndPileProps) => {
  const { setNodeRef, active, isOver } = useDroppable({
    id: PLAY_AREA_ID,
  });

  const isValidDrop =
    active &&
    availablePlays.find((x) =>
      x.cardsToPlay.some((c) => c.card.cardId === active.id)
    );

  const showInvalidDrop = !!active && !isValidDrop;

  return (
    <div className={'flex justify-center relative'}>
      <div
        className={classNames(
          'flex pt-15 relative text-lg items-end overflow-visible flex-shrink'
        )}
      >
        {deckRemaining.length > 0 && <Deck deckRemaining={deckRemaining} />}
        <Pile playedCards={playedCards} lastPlayedCard={lastPlayedCard} />
        <BurntCards burntCards={burntCards} />
      </div>
      <div
        className={classNames(
          ' transition-colors duration-100 rounded-xl drag-target  m-4 absolute mx-auto',
          {
            'outline-2 outline-dashed outline-[#607d8b] bg-[#607d8b22]':
              !!active,
            'bg-red-300': showInvalidDrop,
            'bg-[#607d8b50]': isOver,
          }
        )}
        ref={setNodeRef}
      >
        <div className="m-12">
          <Card card={{ cardId: 'invisibleDeckCard' }} invisible={true} />
        </div>
        {active ? (
          <p className="absolute top-2 lg:text-xl text-center w-full">
            {showInvalidDrop ? 'Invalid play' : 'Drop here to play!'}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default DeckAndPile;
