/* eslint-disable @typescript-eslint/no-empty-function */
import { IHandCard } from '../../../Models/CardModels';
import DraggableCard from '../DraggableCard/DraggableCard';
import { SortableContext } from '@dnd-kit/sortable';

export interface IHandCardsProps {
  handCards: IHandCard[];
}

export default function HandCards(props: IHandCardsProps) {
  const { handCards } = props;

  return (
    <SortableContext items={handCards.map((c) => c.card.cardId)}>
      <div className={`px-5 max-h-full max-w-full overflow-x-auto flex `}>
        {handCards.map((c, i) => (
          <DraggableCard
            key={c.card.cardId}
            card={c}
            className="not-last:shrink not-last:min-w-10"
          ></DraggableCard>
        ))}
      </div>
    </SortableContext>
  );
}
