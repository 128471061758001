import { AppActionsEnum, SetAppStateActions } from '../Actions/AppActions';
import { GameActionsEnum, SetGameStateActions } from '../Actions/GameActions';
import {
  LobbyActionsEnum,
  SetLobbyStateActions,
} from '../Actions/LobbyActions';
import { Screen } from '../Components/General/StartGame/StartGame';
import { generateUUID } from '../functions';
import { CardValue } from '../Models/CardModels';
import { IGameConfig, SpecialCardPlayMode } from '../Models/GameSetupModels';
import { ILeaderboard } from '../Models/LeaderboardModels';
import { IChatMessage, IVisibleLobby } from '../Models/LobbyModels';
import { IVisibleGameState, IVisiblePlay } from '../Models/StateModels';

export interface IConnectionState {
  connected: boolean;
  numRetries: number;
  waitFactor: number;
}

export interface IStore {
  visibleGameState?: IVisibleGameState;
  reconnectToken?: string;
  playerId?: number;
  gameId?: string;
  availableLobbies: IVisibleLobby[];
  currentLobby?: IVisibleLobby;
  currentLobbyId?: string;
  lobbyAdminToken?: string;
  userId: string;
  errors: string[];
  currentScreen: Screen;
  gameConnectionState: IConnectionState;
  lobbyConnectionState: IConnectionState;
  playHistory: IVisiblePlay[];
  name: string;
  gameConfig: IGameConfig;
  messages: IChatMessage[];
  leaderboard: ILeaderboard;
}

// We're just having one big reducer (i.e not using combineReducers) because we dont have a big state.
// If visibleGameState was sent to us in pieces you would have a seperate reducer
// for updating the visibleGameState one piece at a time.
// I always think once you get more than 10 actions its time to have a separate reducer.
export const monoReducer = (
  state: IStore | undefined,
  action: SetGameStateActions | SetAppStateActions | SetLobbyStateActions | null
) => {
  if (!state) {
    const userId = localStorage.getItem('stored-user-id') || generateUUID();

    const initialState: IStore = {
      errors: [],
      playHistory: [],
      currentScreen: Screen.HOME,
      userId,
      name: '',
      gameConfig: {
        numberOfPlayers: 2,
        numberOfBots: 0,
        gameRules: {
          numberOfHandCards: 3,
          numberOfHiddenCards: 3,
          numberOfVisibleCards: 3,
          dynamicRuleSetOptions: {
            canPickupVisibleCardsAsPartOfPlay: true,
            playAgainAfterForcingAPickup: false,
            mustPlayIfCanPlay: true,
            mustShowHiddenFlip: true,
            numberInARowToBurn: 4,
            playAfterWinner: true,
            specialCards: {
              [CardValue.TWO]: {
                burns: false,
                playMode: SpecialCardPlayMode.CanAlwaysPlay,
                resetsThePile: true,
                switchesNextPlayToDescending: false,
                glass: false,
              },
              [CardValue.TEN]: {
                burns: true,
                playMode: SpecialCardPlayMode.CanAlwaysPlay,
                resetsThePile: true,
                switchesNextPlayToDescending: false,

                glass: false,
              },
              [CardValue.EIGHT]: {
                burns: false,
                playMode: SpecialCardPlayMode.Unmodified,
                resetsThePile: false,
                switchesNextPlayToDescending: true,
                glass: false,
              },
            },
          },
        },
      },
      gameConnectionState: { connected: false, numRetries: 0, waitFactor: 200 },
      lobbyConnectionState: {
        connected: false,
        numRetries: 0,
        waitFactor: 500,
      },
      availableLobbies: [],
      messages: [],
      leaderboard: {
        entries: [],
      },
    };
    return initialState;
  }
  if (!action) {
    return state;
  }
  // We have strongly typed actions so typescript can infer the payload type of each different action
  // In reality though we dont have object 'Type' at runtime because their plain objects with properties
  // So each action has a type: property. The combination of these two things allows us to have fully type reducers.
  switch (action.type) {
    case GameActionsEnum.GAME_STATE_SET_GAME_ID:
      return setGameId(state, action.payload);
    case GameActionsEnum.GAME_STATE_SET_PLAYER_ID:
      return setPlayerId(state, action.payload);
    case GameActionsEnum.GAME_STATE_SET_VISIBLE_STATE:
      return setVisibleGameState(state, action.payload);
    case GameActionsEnum.GAME_STATE_SET_RECONNECT_TOKEN:
      return setReconnectToken(state, action.payload);
    case GameActionsEnum.GAME_STATE_ADD_VISIBLE_PLAY:
      return addVisiblePlay(state, action.payload);
    case GameActionsEnum.GAME_STATE_CLEAR_ALL:
      return clearAllGameState(state);

    case AppActionsEnum.APP_STATE_SET_BEFORE_GAME_SCREEN:
      return setBeforeGameScreen(state, action.payload);
    case AppActionsEnum.APP_STATE_ADD_ERROR:
      return addError(state, action.payload);
    case AppActionsEnum.APP_STATE_REMOVE_ERROR:
      return removeError(state, action.payload);
    case AppActionsEnum.APP_STATE_SET_GAME_CONNECTION_STATE:
      return setStateGameConnectionState(state, action.payload);
    case AppActionsEnum.APP_STATE_SET_LOBBY_CONNECTION_STATE:
      return setStateLobbyConnectionState(state, action.payload);
    case AppActionsEnum.APP_STATE_SET_NAME:
      return setName(state, action.payload);
    case AppActionsEnum.APP_STATE_SET_GAMECONFIG:
      return setGameConfig(state, action.payload);
    case AppActionsEnum.APP_STATE_REFRESH_LEADERBOARD:
      return setLeaderboard(state, action.payload);

    case LobbyActionsEnum.LOBBY_STATE_CLEAR_ALL:
      return clearAllLobbyState(state);
    case LobbyActionsEnum.LOBBY_STATE_SET_AVAILABLE_LOBBIES:
      return setAvailableLobbies(state, action.payload);
    case LobbyActionsEnum.LOBBY_STATE_SET_CURRENT_LOBBY:
      return setCurrentLobby(state, action.payload);
    case LobbyActionsEnum.LOBBY_STATE_SET_LOBBY_ADMIN_TOKEN:
      return setCurrentLobbyAdminToken(state, action.payload);
    case LobbyActionsEnum.LOBBY_STATE_NEW_CHAT_MESSAGE:
      return addNewMessage(state, action.payload);
    default:
      return state;
  }
};

function setCurrentLobby(
  state: IStore,
  lobby: IVisibleLobby | undefined
): IStore {
  return {
    ...state,
    currentLobbyId: (lobby && lobby.id) || undefined,
    currentLobby: lobby,
  };
}
function setCurrentLobbyAdminToken(
  state: IStore,
  token: string | undefined
): IStore {
  return {
    ...state,
    lobbyAdminToken: token,
  };
}

function setAvailableLobbies(state: IStore, lobbies: IVisibleLobby[]): IStore {
  return {
    ...state,
    availableLobbies: lobbies,
  };
}

function clearAllLobbyState(state: IStore): IStore {
  return {
    ...state,
    availableLobbies: [],
    currentLobby: undefined,
    currentLobbyId: undefined,
    lobbyAdminToken: undefined,
    messages: [],
  };
}

function addNewMessage(state: IStore, message: IChatMessage): IStore {
  return {
    ...state,
    messages: [...state.messages, message],
  };
}

function clearAllGameState(state: IStore): IStore {
  return {
    ...state,
    visibleGameState: undefined,
    gameId: undefined,
    reconnectToken: undefined,
    playerId: undefined,
    playHistory: [],
    currentScreen: Screen.HOME,
  };
}

function setBeforeGameScreen(state: IStore, screen: Screen): IStore {
  return { ...state, currentScreen: screen };
}

function setName(state: IStore, name: string): IStore {
  return { ...state, name };
}

function setGameConfig(state: IStore, config: IGameConfig): IStore {
  return { ...state, gameConfig: config };
}

function setLeaderboard(state: IStore, leaderboard: ILeaderboard): IStore {
  return { ...state, leaderboard };
}

function addVisiblePlay(state: IStore, vp: IVisiblePlay): IStore {
  return { ...state, playHistory: [...state.playHistory, vp] };
}

function setStateGameConnectionState(
  state: IStore,
  payload: IConnectionState
): IStore {
  return { ...state, gameConnectionState: payload };
}

function setStateLobbyConnectionState(
  state: IStore,
  payload: IConnectionState
): IStore {
  return { ...state, lobbyConnectionState: payload };
}

function addError(state: IStore, payload: unknown): IStore {
  const error = payload;

  return {
    ...state,
    errors: [...state.errors, (error as Error).message],
  };
}

function removeError(state: IStore, msg: string): IStore {
  const errs = [...state.errors].filter((x) => x !== msg);
  return { ...state, errors: errs };
}

function setPlayerId(state: IStore, payload: number | undefined): IStore {
  const playerId = payload;
  return { ...state, playerId };
}

function setGameId(state: IStore, payload: string | undefined): IStore {
  const gameId = payload;
  return { ...state, gameId };
}

function setReconnectToken(state: IStore, payload: string | undefined): IStore {
  const reconnectToken = payload;
  return { ...state, reconnectToken };
}

// In the future you would have a separate reducer for handling deltas of IVisibleGameState
function setVisibleGameState(
  state: IStore,
  payload: IVisibleGameState | undefined
): IStore {
  const visibleGameState = payload;
  return { ...state, visibleGameState };
}
