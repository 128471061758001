import { Component } from 'react';
import { IHiddenCard, IVisibleCard } from '../../../Models/CardModels';
import DraggableCard from '../DraggableCard/DraggableCard';
import './TableCards.css';

export interface ITableCardsProps {
  visibleCards: IVisibleCard[];
  hiddenCards: IHiddenCard[];
}

class TableCards extends Component<ITableCardsProps> {
  public render() {
    const { hiddenCards, visibleCards } = this.props;
    return (
      <div className="table-cards">
        <div className="table-cards-container">
          <div className="hidden-cards">
            {hiddenCards.map((c, i) => (
              <DraggableCard
                key={c.card.cardId}
                card={c}
                disabled={i < visibleCards.length}
              />
            ))}
          </div>
          <div className="visible-cards z-10 pointer-events-none">
            {visibleCards.map((c) => (
              <DraggableCard key={c.card.cardId} card={c} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default TableCards;
