import classNames from 'classnames';
import { ISourcedCard } from '../../../Models/CardModels';
import Card from '../../General/Card/Card';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export interface IDraggableCardProps {
  card: ISourcedCard;
  className?: string;
  disabled?: boolean;
}

const DraggableCard = ({ card, className, disabled }: IDraggableCardProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: card.card.cardId,
    disabled: { draggable: disabled, droppable: disabled },
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      key={card.card.cardId}
      className={classNames(
        'inline-block',
        'touch-none pointer-events-auto',
        className,
        {
          'opacity-0': isDragging,
        }
      )}
    >
      <Card card={card.card} />
    </div>
  );
};

export default DraggableCard;
