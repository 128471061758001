import { Component, JSX } from 'react';
import { connect } from 'react-redux';
import { appActions } from './Actions/AppActions';
import { gameActions } from './Actions/GameActions';
import { lobbyActions } from './Actions/LobbyActions';
import './App.output.css';
import Errors from './Components/General/Errors/Errors';
import Reconnect from './Components/General/Reconnect/Reconnect';
import StartGame, { Screen } from './Components/General/StartGame/StartGame';
import GameScreen from './Components/General/GameScreen/GameScreen';
import { DispatchWithMiddleWare } from './definitions';
import { ICard, ISourcedCard } from './Models/CardModels';
import { IGameConfig } from './Models/GameSetupModels';
import { IStore } from './Reducers';
import {
  IUpdateLobbyArgs,
  IAdditionalGameProperties,
} from './Models/LobbyModels';

// So we map the data from our store that we want
export function mapStateToProps(state: IStore) {
  return {
    visibleGameState: state.visibleGameState,
    playerId: state.playerId,
    gameId: state.gameId,
    currentLobby: state.currentLobby,
    lobbyAdminToken: state.lobbyAdminToken,
    lobbyList: state.availableLobbies,
    userId: state.userId,
    errors: state.errors,
    currentScreen: state.currentScreen,
    gameConnectionState: state.gameConnectionState,
    lobbyConnectionState: state.lobbyConnectionState,
    playHistory: state.playHistory,
    name: state.name,
    gameConfig: state.gameConfig,
    messages: state.messages,
    leaderboard: state.leaderboard,
  };
}

// When this function executes during connect (see bottom of file)
// we get access to the redux dispatch. We create functions that are bound to the dispatcher.
// You can just inject dispatch into your component or get it from context.
// But this methodology allows you to pass these functions down into components
// and its declarative
export function mapDispatchToProps(dispatch: DispatchWithMiddleWare) {
  return {
    createAndJoinLobby: (lobbyName: string, userName: string) =>
      dispatch(lobbyActions.createAndJoinLobby(lobbyName, userName)),
    joinLobby: (lobbyId: string, userName: string) =>
      dispatch(lobbyActions.joinLobby(lobbyId, userName)),
    leaveLobby: () => {
      dispatch(appActions.setBeforeGameScreen(Screen.LOBBY_LIST));
      dispatch(lobbyActions.leaveLobbyIfInLobby());
    },
    startLobbyGame: (
      lobbyId: string,
      adminToken: string,
      config: IGameConfig
    ) => dispatch(lobbyActions.startNewGame(lobbyId, adminToken, config)),
    refreshLobbies: () => dispatch(lobbyActions.refreshLobbies()),
    leaveGame: async (gameId: string) => {
      await dispatch(gameActions.leaveGame(gameId));
      await dispatch(lobbyActions.clearCurrentGame());
    },
    attemptPickUp: () => dispatch(gameActions.attemptPickUp()),
    attemptPlay: (play: ISourcedCard[]) =>
      dispatch(gameActions.attemptPlay(play)),
    attemptSwap: (card1: ISourcedCard, card2: ISourcedCard) =>
      dispatch(gameActions.attemptSwap(card1, card2)),
    attemptReorderHandCards: (cards: ICard[]) =>
      dispatch(gameActions.attemptReorderHandCards(cards)),
    addError: (error: Error) => dispatch(appActions.addError(error)),
    removeError: (msg: string) => dispatch(appActions.removeError(msg)),
    updateBeforeGameScreen: (screen: Screen) =>
      dispatch(appActions.setBeforeGameScreen(screen)),
    setName: (name: string) => dispatch(appActions.setName(name)),
    attemptGameReconnect: () => dispatch(appActions.attemptGameReconnect()),
    attemptLobbyReconnect: () => dispatch(appActions.attemptLobbyReconnect()),
    updateLobby: (args: IUpdateLobbyArgs) =>
      dispatch(lobbyActions.updateLobby(args)),
    refreshLeaderboard: () => dispatch(appActions.refreshLeaderboard()),
    sendMessage: (message: string) =>
      dispatch(lobbyActions.sendMessage(message)),
    replaceBotInGame: () => dispatch(gameActions.replaceBotInGame()),
  };
}

// Milk
type IPropsDispatch = ReturnType<typeof mapDispatchToProps>;
type IPropsData = ReturnType<typeof mapStateToProps>;
type IProps = IPropsData & IPropsDispatch;

export class App extends Component<IProps> {
  public render() {
    const {
      visibleGameState,
      gameId,
      errors,
      playHistory,
      playerId,
      currentScreen,
      gameConnectionState,
      lobbyConnectionState,
    } = this.props;
    const {
      attemptPickUp,
      attemptPlay,
      attemptSwap,
      attemptReorderHandCards,
      leaveGame,
      updateBeforeGameScreen,
      name,
      gameConfig,
      setName,
      removeError,
      createAndJoinLobby,
    } = this.props;
    const easyLeave = () =>
      gameId
        ? leaveGame(gameId)
        : Promise.reject(new Error('No game to leave.'));
    const scr =
      visibleGameState && playerId != null ? (
        <GameScreen
          handleError={this.handleError}
          visibleGameState={visibleGameState}
          tryPickup={attemptPickUp}
          trySwap={attemptSwap}
          tryPlay={attemptPlay}
          tryReorderHandCards={attemptReorderHandCards}
          playerId={playerId}
          playHistory={playHistory}
          leaveGame={easyLeave}
          lobby={this.props.currentLobby}
          messages={this.props.messages}
          sendMessage={this.props.sendMessage}
        />
      ) : (
        <StartGame
          joinLobby={this.joinLobbyHandler}
          startLobbyGame={this.startLobbyGameHandler}
          gameId={gameId}
          currentLobby={this.props.currentLobby}
          refreshLobbies={this.props.refreshLobbies}
          isLobbyAdmin={!!this.props.lobbyAdminToken}
          userId={this.props.userId}
          lobbyList={this.props.lobbyList}
          leaveLobby={this.props.leaveLobby}
          currentScreen={currentScreen}
          updateBeforeGameScreen={updateBeforeGameScreen}
          name={name}
          gameConfig={gameConfig}
          setName={setName}
          createAndJoinLobby={createAndJoinLobby}
          updateLobby={this.updateLobby}
          leaderboard={this.props.leaderboard}
          refreshLeaderboard={this.props.refreshLeaderboard}
          replaceBotInGame={this.props.replaceBotInGame}
        />
      );
    let reconnecterComponent: JSX.Element | undefined = undefined;
    if (!gameConnectionState.connected || !lobbyConnectionState.connected) {
      reconnecterComponent = (
        <Reconnect
          attemptReconnect={this.attemptReconnect}
          connectionState={{
            connected: false,
            numRetries: Math.max(
              lobbyConnectionState.numRetries,
              gameConnectionState.numRetries
            ),
            waitFactor: Math.max(
              lobbyConnectionState.waitFactor,
              gameConnectionState.waitFactor
            ),
          }}
        />
      );
    }
    return (
      <div className="App">
        <Errors removeError={removeError} errors={errors} />
        {reconnecterComponent}
        {scr}
      </div>
    );
  }

  private attemptReconnect = () => {
    this.props.attemptGameReconnect();
    this.props.attemptLobbyReconnect();
  };

  private updateLobby = (
    name: string,
    additionalGameProperties: IAdditionalGameProperties
  ) => {
    const { currentLobby, lobbyAdminToken, updateLobby } = this.props;
    if (!currentLobby || !lobbyAdminToken) {
      return Promise.reject('Not in a lobby, or not the lobby admin!');
    }

    return updateLobby({
      id: currentLobby.id,
      adminToken: lobbyAdminToken,
      name,
      additionalGameProperties,
    });
  };

  private joinLobbyHandler = (lobbyId: string) => {
    return this.props.joinLobby(lobbyId, this.props.name);
  };

  private startLobbyGameHandler = (config: IGameConfig) => {
    if (!this.props.currentLobby || !this.props.lobbyAdminToken) {
      return Promise.reject('Not in a lobby, or not the lobby admin!');
    }
    return this.props.startLobbyGame(
      this.props.currentLobby.id,
      this.props.lobbyAdminToken,
      config
    );
  };

  private handleError = (e: Error) => {
    this.props.addError(e);
  };
}

// Connect it up
// https://gist.github.com/gaearon/1d19088790e70ac32ea636c025ba424e this explains how it works
export default connect(mapStateToProps, mapDispatchToProps)(App);
