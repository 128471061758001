import classNames from 'classnames';
import { Component } from 'react';
import { isEmoji } from '../../../functions';
import { ICard, ISourcedCard, PlayerHand } from '../../../Models/CardModels';
import { IChatMessage } from '../../../Models/LobbyModels';
import { IVisibleGameState, IVisiblePlay } from '../../../Models/StateModels';
import Opponents from '../../Opponent/Opponents';
import Player from '../../Player/Player';
import DeckAndPile, { PLAY_AREA_ID } from '../DeckAndPile/DeckAndPile';
import Ephemeral from '../Ephemeral/Ephemeral';
import GameOver from '../GameOver/GameOver';
import PlayHistory from '../PlayHistory/PlayHistory';
import './GameState.css';
import {
  DndContext,
  DragOverlay,
  DragEndEvent,
  DragStartEvent,
} from '@dnd-kit/core';
import Card from '../Card/Card';
import { arrayMove } from '@dnd-kit/sortable';

interface IGameStateProps {
  visibleGameState: IVisibleGameState;
  playerId: number;
  playHistory: IVisiblePlay[];
  tryPlay: (play: ISourcedCard[]) => Promise<void>;
  leaveGame: () => Promise<void>;
  trySwap: (card1: ISourcedCard, card2: ISourcedCard) => Promise<void>;
  tryPickup: () => void;
  tryReorderHandCards: (cards: ICard[]) => Promise<void>;
  handleError: (e: Error) => void;

  sendMessage: (message: string) => void;

  messages: IChatMessage[];
}

interface IGameStateState {
  tab: 'game' | 'chat' | 'settings';
  emojiPickerActive: boolean;
  draggingCardId: string | undefined;
}

class GameState extends Component<IGameStateProps, IGameStateState> {
  state: IGameStateState = {
    tab: 'game',
    emojiPickerActive: false,
    draggingCardId: undefined,
  };

  public render() {
    const { leaveGame, handleError } = this.props;
    const {
      opponents,
      lastPlayedCard,
      deckCards,
      playedCards,
      burntCards,
      player,
      gameOver,
    } = this.props.visibleGameState;
    const { playHistory } = this.props;
    const displayedOpponents = opponents.map((opponent) => ({
      opponent,
      lastSentMessage: this.props.messages
        .filter((x) => x.playerIdHash === opponent.idHash)
        .pop(),
    }));
    const lastPlayerMessage = this.props.messages
      .filter((x) => x.playerIdHash === player.idHash)
      .pop();

    const gameOverLayout = gameOver ? (
      <GameOver
        visibleGameState={this.props.visibleGameState}
        leaveGame={leaveGame}
      />
    ) : null;

    const activeDraggingCard = this.state.draggingCardId
      ? this.props.visibleGameState.player.hiddenCards
          .concat(this.props.visibleGameState.player.handCards)
          .concat(this.props.visibleGameState.player.visibleCards)
          .find((c) => c.cardId === this.state.draggingCardId)
      : undefined;

    return (
      <DndContext
        onDragStart={this.handleDragStart}
        onDragEnd={this.handleDragEnd}
      >
        <div className="game-state-wrapper">
          <div className="game-state">
            <Opponents
              playerIndex={player.playerIndex}
              opponents={displayedOpponents}
            />
            <section
              className={classNames('turn-indicator', {
                'your-turn': player.isTurn,
                'opponents-turn': !player.isTurn,
              })}
            >
              <div className="spacer"></div>
              {player.isTurn && <p className="turn">Your Turn</p>}
              {!player.isTurn && <p className="turn">Opponent's Turn</p>}
              <div className="emoji-btn-container">
                {isEmoji(lastPlayerMessage && lastPlayerMessage.message) ? (
                  <Ephemeral
                    id={lastPlayerMessage}
                    message={lastPlayerMessage && lastPlayerMessage.message}
                    classNames="player-last-message"
                  />
                ) : undefined}
                <button
                  className="emoji-btn emoji-btn-core"
                  onClick={this.toggleEmojiPicker}
                >
                  <span role="img" aria-label="emojis">
                    😄
                  </span>
                </button>
                {this.renderEmojiPicker()}
              </div>
            </section>
            <DeckAndPile
              deckRemaining={deckCards}
              lastPlayedCard={lastPlayedCard}
              playedCards={playedCards}
              burntCards={burntCards}
              availablePlays={player.availablePlays}
            />
            <PlayHistory
              playHistory={playHistory}
              player={player}
              opponents={opponents}
            />
            <Player
              player={player}
              handleError={handleError}
              tryReorderHandCards={this.props.tryReorderHandCards}
              tryPickup={this.props.tryPickup}
            />
          </div>
        </div>
        {gameOverLayout}

        <DragOverlay>
          {activeDraggingCard ? <Card card={activeDraggingCard}></Card> : null}
        </DragOverlay>
      </DndContext>
    );
  }

  private handleDragStart = (event: DragStartEvent) => {
    this.setState({ draggingCardId: event.active.id as string });
  };

  private handleDragEnd = (e: DragEndEvent) => {
    this.setState({ draggingCardId: undefined });
    const { active, over } = e;
    const draggedCardIsHandCard =
      this.props.visibleGameState.player.handCards.some(
        (c) => c.cardId === active.id
      );
    // Hand card being dragged over something
    if (draggedCardIsHandCard && over) {
      const isReordering = this.props.visibleGameState.player.handCards.some(
        (c) => c.cardId === over.id
      );
      if (isReordering && active.id !== over.id) {
        const handCards = this.props.visibleGameState.player.handCards;
        const originalIndex = handCards.findIndex(
          (c) => c.cardId === active.id
        );
        const newIndex = handCards.findIndex((c) => c.cardId === over.id);
        const newIdOrder = arrayMove(
          handCards.map((x) => x.cardId),
          originalIndex,
          newIndex
        );
        this.props.tryReorderHandCards(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          newIdOrder.map((id) => handCards.find((c) => c.cardId === id)!)
        );
        return;
      }
    }

    const draggedCard = this.props.visibleGameState.player.hiddenCards
      .map((x) => ({ source: PlayerHand.HIDDEN, card: x }) as ISourcedCard)
      .concat(
        this.props.visibleGameState.player.handCards.map(
          (x) => ({ source: PlayerHand.HAND, card: x }) as ISourcedCard
        )
      )
      .concat(
        this.props.visibleGameState.player.visibleCards.map(
          (x) => ({ source: PlayerHand.VISIBLE, card: x }) as ISourcedCard
        )
      )
      .find((x) => x.card.cardId === active.id);

    // is over play zone
    if (
      over?.id === PLAY_AREA_ID &&
      draggedCard &&
      this.props.visibleGameState.player.availablePlays.some((x) =>
        x.cardsToPlay.some((c) => c.card.cardId === draggedCard.card.cardId)
      )
    ) {
      this.props.tryPlay([draggedCard]);
      return;
    }

    // is over another card
    if (
      this.props.visibleGameState.player.canSwap &&
      over &&
      draggedCard &&
      over.id !== draggedCard.card.cardId
    ) {
      const overCard = this.props.visibleGameState.player.hiddenCards
        .map((x) => ({ source: PlayerHand.HIDDEN, card: x }) as ISourcedCard)
        .concat(
          this.props.visibleGameState.player.handCards.map(
            (x) => ({ source: PlayerHand.HAND, card: x }) as ISourcedCard
          )
        )
        .concat(
          this.props.visibleGameState.player.visibleCards.map(
            (x) => ({ source: PlayerHand.VISIBLE, card: x }) as ISourcedCard
          )
        )
        .find((x) => x.card.cardId === over.id);
      if (overCard) {
        this.props.trySwap(draggedCard, overCard);
        return;
      }
    }
  };

  renderEmojiPicker = () => {
    if (!this.state.emojiPickerActive) return undefined;
    return (
      <div onClick={this.toggleEmojiPicker} className="emoji-picker">
        <button className="emoji-btn-core" onClick={this.sendMessage('👍')}>
          <span role="img" aria-label="thumbs up">
            👍
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('😄')}>
          <span role="img" aria-label="big smile">
            😄
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('😍')}>
          <span role="img" aria-label="heart eyes">
            😍
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('😮')}>
          <span role="img" aria-label="wow">
            😮
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('💩')}>
          <span role="img" aria-label="poo">
            💩
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('😠')}>
          <span role="img" aria-label="angry">
            😠
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('😢')}>
          <span role="img" aria-label="cry">
            😢
          </span>
        </button>
        <button className="emoji-btn-core" onClick={this.sendMessage('🥴')}>
          <span role="img" aria-label="woozy">
            🥴
          </span>
        </button>
      </div>
    );
  };

  toggleEmojiPicker = () => {
    this.setState({ emojiPickerActive: !this.state.emojiPickerActive });
  };

  messageFunctions: { [msg: string]: () => void } = {};
  sendMessage = (message: string) => {
    if (this.messageFunctions[message]) {
      return this.messageFunctions[message];
    }
    this.messageFunctions[message] = () => {
      this.props.sendMessage(message);
    };
    return this.messageFunctions[message];
  };
}

export default GameState;
